import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Footer from "../components/footer"
import Contact from "../components/contact"

const Eating = () => (
  <Layout>
    <SEO
      lang="it"
      title="Disturbi del comportamento alimentare | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="Descrizione dei disturbi del comportamento alimentare di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div style={{ width: "1050px", margin: "0 auto", paddingLeft: "16px" }}>
        <Link to="/">
          <span>Indietro</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#f8f8f8">
      <div style={{ paddingTop: "64px" }}>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              Disturbi del comportamento alimentare
            </span>{" "}
          </h2>
        </div>
        <div className="paddings-lr why-content">
          <p>
            Anoressia e bulimia sono tra i disturbi alimentari più ampiamente
            trattati.
          </p>
          <p>
            La persona anoressica mostra un peso corporeo estremamente basso,
            autolimitazione del cibo, intensa paura di acquisire peso,
            distorsione della valutazione del proprio peso corporeo e amenorrea
            per almeno 3 cicli mestruali consecutivi.
          </p>
          <p>
            La persona bulimica è caratterizzata da esagerata preoccupazione per
            l’immagine del proprio corpo, da abbuffate e comportamenti
            compensatori (assunzione di calorie contrastata attraverso vomito,
            uso di lassativi, diuretici o esercizio fisico eccessivo).
          </p>
          <p>
            I disturbi dell’alimentazione sono associati ad alti tassi di
            mortalità.
          </p>
        </div>
      </div>
    </Section>
    <Contact />
    <Footer />
  </Layout>
)

export default Eating
